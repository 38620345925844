
import React from "react";
import { useTranslation } from "react-i18next"
import HeroImg from "../../components/HeroImg/HeroImg";
import MainLayout from "../../layouts/MainLayout";
import "./plum.scss";
import { Link } from 'gatsby';

const Plum = () => {
  const { t } = useTranslation()
  return (
    <MainLayout metaTitle={t("plum:metaTitle")}>
      <>
        <HeroImg customClass="plum-hero" subtitle={t("plum:heroSubtitle")} customSubtitle="plum-subtitle" title={t("plum:heroTitle")} backgroundImage="bg/plum-hero.jpg"></HeroImg>

        <div className="plum">

          <div className="plum-section-one-mobile">
            <h1 className="box plum-section-one-title">{t("plum:sectionOneTitle")}</h1>
            <p className="box">{t("plum:sectionOneTextOne")}</p>
            <p className="box">{t("plum:sectionOneTextTwo")}</p>
            <p className="box">{t("plum:sectionOneTextThree")}</p>
            <p>{t("plum:sectionOneTextFour")}</p>
          </div>
          <HeroImg customClass="plum-section-one" backgroundImage="bg/plum-section-one.jpg">
            {<div className="plum-section-one-container">
              <h1 className="box plum-section-one-title">{t("plum:sectionOneTitle")}</h1>
              <p className="box">{t("plum:sectionOneTextOne")}</p>
              <p className="box">{t("plum:sectionOneTextTwo")}</p>
              <p className="box">{t("plum:sectionOneTextThree")}</p>
              <p>{t("plum:sectionOneTextFour")}</p>
            </div>}
          </HeroImg>

          <HeroImg customClass="plum-section-two" backgroundImage="bg/plum-section-two.jpg">
            {<div className="plum-section-two-container">
              <h4>{t("common:organoleptic")}</h4>
              <h3 className="box">{t("common:properties")}</h3>
              <div className="hide-on-mobile">
                <h4>{t("common:color")}</h4>
                <p className="box-two">{t("plum:sectionTwoTextOne")}</p>
                <h4>{t("common:aroma")}</h4>
                <p>{t("plum:sectionTwoTextTwo")}</p>
                <p>{t("plum:sectionTwoTextThree")}</p>
                <p className="box-two">{t("plum:sectionTwoTextFour")}</p>
                <h4>{t("common:palate")}</h4>
                <p>{t("plum:sectionTwoTextFive")}</p>
                <p>{t("plum:sectionTwoTextSix")}</p>
                <p className="box-two">{t("plum:sectionTwoTextSeven")}</p>
                <h4>{t("common:aftertaste")}</h4>
                <p>{t("plum:sectionTwoTextEight")}</p>
                <p>{t("plum:sectionTwoTextNine")}</p>
                <p>{t("plum:sectionTwoTextTen")}</p>
              </div>
            </div>}
          </HeroImg>
          <div className="plum-section-two-mobile">
            <h4>{t("common:color")}</h4>
            <p className="box-two">{t("plum:sectionTwoTextOne")}</p>
            <h4>{t("common:aroma")}</h4>
            <p>{t("plum:sectionTwoTextTwo")}</p>
            <p>{t("plum:sectionTwoTextThree")}</p>
            <p className="box-two">{t("plum:sectionTwoTextFour")}</p>
            <h4>{t("common:palate")}</h4>
            <p>{t("plum:sectionTwoTextFive")}</p>
            <p>{t("plum:sectionTwoTextSix")}</p>
            <p className="box-two">{t("plum:sectionTwoTextSeven")}</p>
            <h4>{t("common:aftertaste")}</h4>
            <p>{t("plum:sectionTwoTextEight")}</p>
            <p>{t("plum:sectionTwoTextNine")}</p>
            <p>{t("plum:sectionTwoTextTen")}</p>
          </div>

          <HeroImg customClass="plum-section-three" backgroundImage="bg/plum-section-three.jpg">
            {
              <div className="plum-section-three-container">
                <h4>{t("plum:sectionThreeTitle")}</h4>
                <h3 className="differente-style box"><span>{t("common:pairing")}</span>{t("common:food")}</h3>
                <div className="hide-on-mobile">
                  <h4 className="differente-style-two">{t("plum:sectionThreeTextOne")}</h4>
                  <p>{t("plum:sectionThreeTextTwo")}</p>
                  <p className="box">{t("plum:sectionThreeTextThree")}</p>
                  <h4>{t("plum:sectionThreeTextFour")}</h4>
                  <p className="box-two">{t("plum:sectionThreeTextFive")}</p>
                  <h4>{t("plum:sectionThreeTextSix")}</h4>
                  <p className="box-two">{t("plum:sectionThreeTextSeven")}</p>
                  <h4>{t("plum:sectionThreeTextEight")}</h4>
                  <p>{t("plum:sectionThreeTextNine")}</p>
                </div>
              </div>
            }
          </HeroImg>
          <div className="plum-section-three-mobile">
            <h4 className="differente-style-two">{t("plum:sectionThreeTextOne")}</h4>
            <p>{t("plum:sectionThreeTextTwo")}</p>
            <p className="box">{t("plum:sectionThreeTextThree")}</p>
            <h4>{t("plum:sectionThreeTextFour")}</h4>
            <p className="box-two">{t("plum:sectionThreeTextFive")}</p>
            <h4>{t("plum:sectionThreeTextSix")}</h4>
            <p className="box-two">{t("plum:sectionThreeTextSeven")}</p>
            <h4>{t("plum:sectionThreeTextEight")}</h4>
            <p>{t("plum:sectionThreeTextNine")}</p>
          </div>
        </div>
        <HeroImg customClass="plum-section-four" backgroundImage="bg/bg-bottle.jpg">
          {
            <div className="plum-section-four-container">
              <h2>{t("common:otherBrandies")}</h2>
              <Link to={t("routes:gordaApricot")}><img src="/img/apricot-small.png" alt="apricot" /></Link>
              <Link to={t("routes:gordaQuince")}><img src="/img/quince-small.png" alt="quince" /></Link>
              <Link to={t("routes:gordaAron")}><img src="/img/aron-small.png" alt="aron" /></Link>   
            </div>
          }
        </HeroImg>
      </>
    </MainLayout>
  )
}

export default Plum;